<template>
  <div>
    <sui-grid>
      <sui-grid-row>
        <sui-grid-column :width="16" :large-screen="13" :widescreen="14">
          <Info
            v-if="caseInfo"
            :case-info="caseInfo"
            @onChange="$emit('onChange')"
          />
        </sui-grid-column>

        <sui-grid-column :width="8" :large-screen="3" :widescreen="2">
          <Payment
            v-if="caseInfo && Object.keys(caseInfo).length > 0"
            :case-info="caseInfo"
          />
        </sui-grid-column>
      </sui-grid-row>

      <sui-grid-row :columns="2">
        <PersonData
          v-if="caseInfo && Object.keys(caseInfo).length > 0"
          :person-data="claimantData"
          :case-info="caseInfo"
          role="claimant"
          @personSave="handleClaimantSave"
        />
        <PersonData
          v-if="caseInfo && Object.keys(caseInfo).length > 0"
          :person-data="debtorData"
          :case-info="caseInfo"
          role="debtor"
          @personSave="handleDebtorSave"
        />
      </sui-grid-row>

      <sui-grid-row :columns="2">
        <sui-grid-column>
          <Solution :case-info="caseInfo" />
        </sui-grid-column>

        <sui-grid-column>
          <sui-form @submit.prevent="saveClaims">
            <Claims
              :data="claims"
              :disabled="claimsDisabled"
              :is-loading="isClaimLoading"
              :case-info="caseInfo"
              @cancel="claimsDisabled = true"
              @delete="deleteClaim"
              @edit="claimsDisabled = false"
              @update="updateClaimsValue"
              @stop="stopClaim"
              @reopen="reopenClaim"
            />
          </sui-form>
        </sui-grid-column>
      </sui-grid-row>
    </sui-grid>
  </div>
</template>
<script>
import api from '@/api';
import Claims from '@/components/Case/Claims/Claims.vue';
import Info from './Info.vue';
import Payment from './Payment.vue';
import PersonData from './PersonData.vue';
import Solution from '@/components/Case/Solution.vue';
import {
  showErrorNotification,
  showSuccessNotification,
} from '@/mixins/notification';
import { removeEmptyValues } from '@/mixins/util';
import EventBus from '@/mixins/event-bus';
import { validateClaim } from '@/mixins/formValidator';
import { triggerIssuerFeeUpdateModalIfNeeded } from '@/components/Case/triggerIssuerFeeUpdateModalIfNeeded';
import { Status as ClaimStatus } from '@/static/enums/claim';

function emptyMoney() {
  return {
    cents: 0,
    currency: 'EUR',
    tarn_id: null,
  };
}

export default {
  name: 'OverallTab',
  components: {
    Claims,
    Info,
    Payment,
    PersonData,
    Solution,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    /**
     * Current case object that was retrieved from v1 api,
     * see EnforcementCase.yml for serialized fields.
     */
    caseInfo: {
      type: Object,
      default: () => {},
    },
    claimantData: {
      type: Object,
      default: () => {},
    },
    debtorData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      claims: [],
      claimsDisabled: true,
      isClaimLoading: false,
    };
  },
  created() {
    this.$watch(
      'caseInfo',
      (eCase) => {
        if (eCase.id) {
          let params = {};
          if (this.$route.query.transfer_id) {
            params = {
              transfer_id: this.$route.query.transfer_id,
            };
          }
          this.getClaims(eCase.id, params);
        }
      },
      { immediate: true }
    );
  },
  methods: {
    getClaims(id, params) {
      api.claim.findByCaseId(id, params).then((response) => {
        if (response.data && response.data.length > 0) {
          const surchargeIds = response.data.map((claim) => claim.surcharge);
          if (surchargeIds.length) {
            this.getSurcharges(response.data, surchargeIds);
          }
        }
      });
    },
    async getSurcharges(claims, idList) {
      api.surcharge.getSurcharges(idList).then((response) => {
        this.claims = claims.map((claim) => {
          const surchargeData = response.data.find(
            (surcharge) => surcharge.id === claim.surcharge
          );

          if (surchargeData) {
            return {
              ...claim,
              surchargeData,
            };
          }

          return claim;
        });
      });
    },
    updateClaimsValue(data) {
      this.claims = data;
    },
    async saveClaims() {
      let invalid = false;
      this.claims.forEach((claim) => {
        const validation = validateClaim(claim);

        if (validation) {
          showErrorNotification({
            text: validation,
          });

          invalid = true;
        }
      });

      if (invalid) return;
      const hasMainClaim = !!this.claims.find((claim) => claim.mainClaim);

      if (!hasMainClaim) {
        showErrorNotification({
          title: this.$t('notification.saveError'),
          text: this.$t('notification.saveClaimError'),
        });

        return;
      }

      this.isClaimLoading = true;

      const promises = this.claims.map((claim) => {
        if (claim.id) {
          return this.updateClaim(claim);
        }

        return this.createClaim(claim);
      });

      const saveClaimsFn = async () => {
        await Promise.all(promises)
          .then(() => {
            this.claimsDisabled = true;
            showSuccessNotification({
              text: this.$t('notification.saveSuccess'),
            });

            api.cases.syncToTarn(this.caseId);
          })
          .catch(() => {
            showErrorNotification({
              title: this.$t('notification.saveError'),
              text: this.$t('notification.saveErrorDescription'),
            });
          })
          .finally(() => {
            this.isClaimLoading = false;
            EventBus.$emit('RELOAD_CASE_DATA');
          });
      };

      await triggerIssuerFeeUpdateModalIfNeeded(
        saveClaimsFn,
        this.$store,
        this.$route.params.id,
        true
      );
    },
    async createClaim(data) {
      const surcharge = await this.createSurcharge(data.surchargeData);
      const response = await api.claim.create({
        ...data,
        enforcement_case_id: this.caseId,
        surcharge,
        sumPeriodical: data.sumPeriodical ? data.sumPeriodical : emptyMoney(),
        periodicAmountAlimony: data.periodicAmountAlimony
          ? data.periodicAmountAlimony
          : emptyMoney(),
        // If claim has expire date, then the status should also be set as "expired"
        status: data.hasOwnProperty('date_expire') && !!data.date_expire
          ? ClaimStatus.EXPIRED
          : (data.status || ClaimStatus.ACTIVE),
      });
      EventBus.$emit('RELOAD_CASE_DATA');
      return response;
    },
    async updateClaim(data) {
      if (data.surchargeData && data.surchargeData.id) {
        this.updateSurcharge(data.surchargeData);
        return api.claim.update(data.id, {
          ...removeEmptyValues(data),
          // If claim has expire date, then the status should also be set as "expired"
          status: data.hasOwnProperty('date_expire') && !!data.date_expire
            ? ClaimStatus.EXPIRED
            : (data.status || ClaimStatus.ACTIVE),
        });
      }
      if (data.surchargeData) {
        const surcharge = await this.createSurcharge(data.surchargeData);

        return api.claim.update(data.id, {
          ...removeEmptyValues(data),
          surcharge,
          // If claim has expire date, then the status should also be set as "expired"
          status: data.hasOwnProperty('date_expire') && !!data.date_expire
            ? ClaimStatus.EXPIRED
            : (data.status || ClaimStatus.ACTIVE),
        });
      }
      EventBus.$emit('RELOAD_CASE_DATA');
      return api.claim.update(data.id, {
        ...removeEmptyValues(data),
        // If claim has expire date, then the status should also be set as "expired"
        status: data.hasOwnProperty('date_expire') && !!data.date_expire
          ? ClaimStatus.EXPIRED
          : (data.status || ClaimStatus.ACTIVE),
      });
    },
    async createSurcharge(surcharge) {
      const { data } = await api.surcharge.create(surcharge);
      return data.id;
    },
    updateSurcharge(surcharge) {
      api.surcharge.update(surcharge.id, surcharge);
    },
    deleteClaim(data) {
      api.claim
        .deleteClaim(data.id)
        .then(() => {
          this.getClaims(this.caseId);
        })
        .finally(() => {
          this.claimsDisabled = true;
          this.getClaims(this.caseId);
          EventBus.$emit('RELOAD_CASE_DATA');
        });
    },
    stopClaim() {
      this.getClaims(this.caseId);
      this.claimsDisabled = true;
      EventBus.$emit('RELOAD_CASE_DATA');
    },
    reopenClaim(data) {
      api.claim
        .reopen(data.id)
        .then(() => {
          this.getClaims(this.caseId);
        })
        .finally(() => {
          this.claimsDisabled = true;
          EventBus.$emit('RELOAD_CASE_DATA');
        });
    },
    handleClaimantSave(id) {
      api.cases.update(this.caseId, {
        claimant: id,
      });
    },
    handleDebtorSave(id) {
      api.cases.update(this.caseId, {
        debtor: id,
      });
    },
  },
};
</script>
<style src="./Overall.css"></style>
