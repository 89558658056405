import req from '../lib/req';
import { AxiosResponse } from 'axios';
import { CaseTransition } from '@/static/enums/CaseTransition';
import { EnforcementCase } from '@/types/EnforcementCase';
import { Id } from '@/types/Id';
import { IssuerFeeCheckResponse } from '@/types/IssuerFeeCheckResponse';
import { PagerParams } from '@/types/PagerParams';

export function checkIssuerFee(
  enforcementCase: EnforcementCase
): Promise<IssuerFeeCheckResponse> {
  return req
    .get(`/v2/enforcement_cases/${enforcementCase.id}/issuer_fee_check`)
    .then((resp: AxiosResponse<IssuerFeeCheckResponse>) => resp.data);
}

export function checkIssuerFeeByCaseId(
  caseId: Id
): Promise<IssuerFeeCheckResponse> {
  return req
    .get(`/v2/enforcement_cases/${caseId}/issuer_fee_check`)
    .then((resp: AxiosResponse<IssuerFeeCheckResponse>) => resp.data);
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const qs = require('qs');

export function find() {
  return req.get('/case');
}

/**
 * @deprecated Use /sync_to_tarn endpoint instead to update or
 * add case to TARN (see SyncCaseToTarnController.php).
 */
export function addToTarn(id: Id) {
  return req.post(`/case/${id}/add-tarn`);
}

/**
 * Queues a TarnV2 message queue for the case of given id in order to
 * attempt to sync changes made to the case to Tarn.
 */
export function syncToTarn(id: Id) {
  return req.get(`/v2/enforcement_cases/${id}/sync_to_tarn`);
}

export function findByParams(params: unknown) {
  return req.get(`/case?${qs.stringify(params)}`);
}

export function recalc(id: Id) {
  return req.post(`/case/${id}/recalc`);
}

/**
 * findById() uses v1 of api
 * @deprecated
 */
export function findById(id: Id, params?: unknown) {
  return req.get(`/case/${id}`, { params });
}

/**
 * fetchById() uses v2 of api
 */
export function fetchById(id: Id): Promise<EnforcementCase> {
  return req
    .get(`/v2/enforcement_cases/${id}`)
    .then((resp: AxiosResponse) => resp.data);
}
export function findByIdList(idList: Id[]) {
  return req.get('/case', {
    params: {
      id: idList,
      limit: idList.length,
    },
  });
}

export function update(id: Id, data: EnforcementCase) {
  return req.put(`/case/${id}`, data);
}

export function create(caseData: EnforcementCase) {
  return req.post('/case', caseData);
}

export function deleteCase(id: Id) {
  return req.delete(`/case/${id}`);
}

export function findEndCases() {
  return req.post('/case/find-end-cases');
}

export function refreshManualArrests() {
  return req.post('/case/refresh-manual-arrests');
}

export function refreshAwaitingArrest() {
  return req.post('/case/refresh-awaiting-arrest');
}

export function validateArrestErrors(id: Id) {
  return req.post(`/case/${id}/validate-arrest-errors`);
}

export function updateFeesAndArrests(id: Id, params: unknown) {
  return req.post(`/case/${id}/update-fees`, params);
}

export function makeCaseTagInactive(caseId: Id, tag: number) {
  return req.post(`/case/${caseId}/deactivate-tag/${tag.toString()}`);
}

export function count(params: []) {
  return req.get('/case/count', {
    params,
  });
}

export function fetchBankAccounts(personId: Id) {
  return req.get(`/bank-account?where={"person":"${personId}"}`);
}

export function refuseTarnCase(id: Id) {
  return req.post(`/case/${id}/refuse-tarn`);
}
export function close(caseId: Id, data: unknown) {
  return req.post(`/case/${caseId}/close`, data);
}
export function changeNo(id: Id, data: unknown) {
  return req.post(`/case/${id}/change-no`, data);
}
export function fetchQueries(caseId: Id, data: unknown) {
  return req.get(`/case/${caseId}/queries`, { params: data });
}
export function sendEndQuery(caseId: Id) {
  return req.post(`/case/${caseId}/send-end`);
}
export function sendAcceptQuery(caseId: Id) {
  return req.post(`/case/${caseId}/send-accept`);
}
export function sendSTEQuery(caseId: Id) {
  return req.post(`/case/${caseId}/send-ste`);
}
export function sendNTKVQuery(caseId: Id) {
  return req.post(`/case/${caseId}/send-ntkv`);
}
export function acceptTarn(id: Id) {
  return req.post(
    `/v2/enforcement_cases/${id}/transition/${CaseTransition.TARN_ACCEPT}`
  );
}
export function updateStatus(id: Id, status: number) {
  return req.post(`/case/${id}/status`, { status });
}
export function arrest(id: Id, data: unknown) {
  return req.post(`/case/${id}/arrest`, data);
}
export function removeArrests(id: Id, data: unknown) {
  return req.post(`/case/${id}/remove-arrests`, data);
}
export function refreshArrestRemove() {
  return req.post('/case/refresh-arrest-remove');
}
export function stop(id: Id, params: unknown) {
  return req.post(`/case/${id}/stop`, params);
}
export function findAwaitingArrestChange(params: PagerParams) {
  return req.get('/case/awaiting-arrest-change', { params });
}
export function findFailedArrests(params: []) {
  return req.get('/case/failed-arrests', { params });
}
export function createNumber(id: Id) {
  return req.post(`/v2/enforcement_cases/${id}/generate_number`);
}
export function fetchArrestSums(id: Id) {
  return req
    .get(`/v2/enforcement_cases/${id}/arrest_sums`)
    .then((resp: AxiosResponse) => resp.data['hydra:member']);
}

/**
 * Finds valid reference number that can be used to create arrests.
 */
export async function getArrestReferenceNumber(caseId: Id): Promise<string> {
  return req
    .get(`/v2/enforcement_cases/${caseId}/arrest_reference_number`)
    .then((response: { data: string }) => response.data);
}

/**
 * Validates given case and returns found validation warnings for it.
 */
export async function getValidationWarnings(
  caseId: Id
): Promise<{ messages: string[] }> {
  return req
    .get(`/v2/enforcement_cases/${caseId}/validate`)
    .then((response: { data: { messages: string[] } }) => response.data);
}
